import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import TitleImg from '../components/TitleImg';
import { htmlParser } from '../helpers/htmlParser';

// eslint-disable-next-line
export const AboutPageTemplate = ({
  title,
  sectionOne,
  sectionTwo,
  sectionThree
}) => {
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="content">
          <div className="columns is-vcentered is-centered">
            <div className="column is-10 is-offset-1 is-centered-content">
            <TitleImg
                  title={title}
                  titleClass={'courses-title'}
                  imgSrc={'/img/about-title-img.png'}
                  imgClass={'courses-title-img'}
                />
              <div className="columns is-vcentered is-centered content-block">
                <div className="column is-6 is-vcentered text-column">
                  <div>{htmlParser(sectionOne.text)}</div>
                </div>
                <div className="column is-6 image-column">
                  <PreviewCompatibleImage
                    className="about-img"
                    imageInfo={sectionOne.image}
                    alt={sectionOne.alt}
                  />
                </div>
              </div>
              <div className="columns is-vcentered content-block">
                <div className="column is-6 is-vcentered is-centered image-column">
                  <PreviewCompatibleImage
                    className="about-img"
                    imageInfo={sectionTwo.image}
                    alt={sectionTwo.alt}
                  />
                </div>
                <div className="column is-6 is-vcentered is-centered text-column">
                  <div>{htmlParser(sectionTwo.text)}</div>
                </div>
              </div>
              <div className="columns is-vcentered content-block">
                <div className="column is-6 text-column">
                  <div>{htmlParser(sectionThree.text)}</div>
                </div>
                <div className="column is-6 image-column">
                  <PreviewCompatibleImage
                    className="about-img"
                    imageInfo={sectionThree.image}
                    alt={sectionThree.alt}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  sectionOne: PropTypes.object,
  sectionTwo: PropTypes.object,
  sectionThree: PropTypes.object
};

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <AboutPageTemplate
        title={frontmatter.title}
        sectionOne={frontmatter.sectionOne}
        sectionTwo={frontmatter.sectionTwo}
        sectionThree={frontmatter.sectionThree}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        title
        sectionOne {
          image {
            childImageSharp {
              gatsbyImageData(height: 320, quality: 100, layout: CONSTRAINED)
            }
          }
          alt
          text
        }
        sectionTwo {
          image {
            childImageSharp {
              gatsbyImageData(height: 320, quality: 100, layout: CONSTRAINED)
            }
          }
          alt
          text
        }
        sectionThree {
          image {
            childImageSharp {
              gatsbyImageData(height: 320, quality: 100, layout: CONSTRAINED)
            }
          }
          alt
          text
        }
      }
    }
  }
`;
